const LABELS = {
  PAGETITLE: {
    MY_NAVIGATOR: "My Navigator",
    CREATION: "Creation",
    KB_KP_TC_CONTRIBUTION: "Contribute Knowledge",
    PREVIEW_PREFIX: "PREVIEW: ",
    FACE_CARD_GENERATOR: "Face Card Generator"
  },
  THUMBSUPDOWN: {
    THANKYOU: "Thank you!",
    THANKYOU_PARA: "This will help us improve the content quality.",
    HEADING: "How would you rate your content quality experience on this collection?",
    REASON_1: "Not relevant to this page",
    REASON_2: "Outdated Content",
    REASON_3: "Poor attachment quality",
    REASON_4: "Other",
    TEXTAREA_PLACEHOLDER: "Please provide the reason for your dissatisfaction",
    SUBMIT: "Submit",
    HEADING_KP: "Rate this Content",
    HEADING_FEEDBACK: "Is this helpful?",
    THUMBSUP: "thumbs up",
    THUMBSDOWN: "thumbs down"
  },
  KPCONSUMPTIONGROUP: {
    AUTHORS: 'Authors',
    ADDITIONAL_CONTACTS: 'Additional Contacts',
    ORIGINAL_REQUESTOR: "Original Requestor",
    SHOW_MORE: 'Show More',
    SHOW_LESS: 'Show Less',
    HEADER_AUDIT: 'The Knowledge Page was updated on ',
    HEADER_AUDIT_BUNDLE: 'The Knowledge Bundle was updated on ',
    HEADER_AUDIT_COLLECTION: 'The Topic Collection was updated on ',
    DOWNLOADS: "Downloads",
    SUBJECTS: "Search and Discovery Tags",
    ASSOCIATED_SECTOR: "Associated PA/Topic/Sector",
    COLLAPSE_TAGS: "Collapse Tags",
    EXPAND_TAGS: "Expand Tags",
    CUSTODIAN: "Custodian PA/Topic/Sector",
    TOPBAR_HEADING_PREVIEW: "Preview Mode",
    TOPBAR_HEADING_UNPUBLISHED: "Unpublished Content",
    TOPBAR_HEADING_ARCHIVED: "Archived Content",
    TOPBAR_MESSAGE_PREVIEW: "Please confirm the content before they are published.",
    TOPBAR_MESSAGE_ARCHIVED_PAGE: " This is an Archived Knowledge Page.",
    TOPBAR_MESSAGE_ARCHIVED_BUNDLE: " This is an Archived Knowledge Bundle.",
    TOPBAR_MESSAGE_ARCHIVED_COLLECTION: " This is an Archived Topic Collection.",
    TOPBAR_MESSAGE_PARECOMM_DISABLED: "PA Recommendations are disabled since a new version is available in Draft status.",
    TOPBAR_HEADING_REVIEW: "Under Review",
    TOPBAR_MESSAGE_REVIEW: "This Knowledge Page is under review and has not been published yet.",
    DOCUMENT_AUDIT: "The file was last updated on ",
    PA_RECOMMENDED: "PA Recommended",
    NOT_PA_RECOMMENDED: "Not Recommended",
    KNOWLEDGE_PAGES: "Knowledge Pages",
    KNOWLEDGE_PAGE: "Knowledge Page",
    LINKS: "Links",
    NO_LINKS_ASSOCIATED: "No links associated with this bundle",
    NO_PAGES_ASSOCIATED: "No Knowledge pages associated with this bundle",
    CHANNELS: "Channels",
    AUTHOR: "Author",
    ADDITIONAL_CONTRIBUTORS: "Additional Contributors",
    ASSOCIATED_TOPIC_PAGES: "Associated Topic pages",
    KP_RESTRICTEDTEXT: "Restricted: Ask the managing director and partner or principal on your case for permission to request the document from ",
    CURATOR: "Curator",
    ASSOCIATED_BUNDLES: "Associated Bundles",
    ASSOCIATED_CASES: "Associated Cases",
    RECOMMENDED: "Recommended",
    EDIT_PAGE: "Edit Page",
  },
  ASSOCIATED_CASES_NO_TITLE: "Case title is not available, click to open the case page.",
  ASSOCIATED_CASES_NO_DETAILS: "Case details not found",
  BUNDLE_TYPE: {
    TOPIC_COLLECTION: "Topic Collections",
  },
  EMAIL: {
    KP_BODY: "Link to Knowledge Page: ",
    KB_BODY: "Link to Knowledge Bundle: ",
    TC_BODY: "Link to Topic Collection: ",
    DESCRIPTION_HEADING: "Description: "
  },
  CLONE: {
    HEADING: "You are about to clone this Knowledge page. Do you want to proceed? Attachment and URL from the original Knowledge Page is not copied over in the clone.",
    BUTTON_CLONE: "ClONE",
    BUTTON_RETRY: "RETRY",
    HEADING_SUCCESS: "This Knowledge page is cloned and ready for editing. Attachment and URL from the original Knowledge Page is not copied over in the clone.",
    HEADING_RETRY: "An error occured while cloning this Knowledge page. Do you wish to retry again?"
  },
  PA_RECOMMENDED: "PA RECOMMENDED",
  PA_RECOMMEND: "PA RECOMMEND",
  OPTIONAL_TEXT: "(Optional)",
  ELLIPSIS: " ...",
  ATTACHMENT_RESTRICTED_MESSAGE: "Attachment restricted to Managing Directors, Partners and Principals",
  TPKB: {
    BREADCRUMB_NEW_KB_TITLE: "Create new bundle",
    BREADCRUMB_EDIT_KB_TITLE: "Edit bundle"
  },
  CP: {
    NO_CASE_AVAILABLE: "Case details not available",
  },
  MY_CASES: {
    OPENED_ON: "Opened on",
    CASE_EDITOR: "Case Editor",
    CASE_CAPTURE_STATUS: "Case Capture Status",
    CASE_E_OFFICE: "E-Office",
    CASE_E_OFFICE_LINK_TEXT: "E-office link",
    CASE_STATUS: "Case Status",
    UPDATED_ON: "Updated on",
    CLIENT: "CLIENT",
    BU: "BU",
    UNSANITIZED_CASE_TITLE: "UN-SANITIZED CASE TITLE",
    TITLE_HEADING: "Case Title *",
    TITLE_PLACEHOLDER: "Please enter title text",
    TITLE_SAVE_BTN: "SAVE TITLE",
    TITLE_SAVE_BTN_LOADING: "SAVING TITLE...",
    ADMINISTRATIVE_TITLE: "Administrative & Reporting",
    SUBJECT_TAGGING: "Search and Discovery",
    ADMIN_SELECT_PA_HEADING: "Select PA Sectors/Topics",
    PAGINATION_LEFT_MESSAGE: "Showing <lv> – <lv> of <lv> Case cards",
  },
  CP_CONTRIBUTION: {
    PAGE_TITLE: "Case Page Contribution",
    ADMIN_SELECTPA_HEADING: "Select PA Sectors/Topics",
    HEADER_RIGHT_BUTTON: "Case History",
    STEP1_TITLE: "Case Overview",
    STEP2_TITLE: "Case Insights",
    STEP3_TITLE: "Case Team",
    STEP4_TITLE: "Knowledge Assets",
    NOTIFICATION_MESSAGE_OVERVIEW: "Please complete highlighted field(s)",
    STEP1_FIELDS: {
      CASE_OFFICE: "Office",
      CASE_OFFICE_PLACEHOLDER: "Choose BCG office",
      CASE_EDITOR: "Case Editor",
      CASE_EDITOR_PLACEOLDER: "Search",
      CASE_TITLE: "Case Title",
      CLIENT_DESCRIPTION: "Client Description",
      CLIENT_BU_DESCRIPTION: "Client Business-Unit Description",
      CONFIDENTIAL: "Confirmed Confidential",
      CASE_DESCRIPTION: "SANITIZED CASE DESCRIPTION",
      BUSINESS_SUBJECT_TAGGING: "BUSINESS/SUBJECT TAGGING",
      T_AND_B: "TIME & BILLING",
      CAPTURE_TOOL: "CASE CAPTURE TOOL",
      OFFICE: "E-OFFICE",
      OFFICE_LINK: "E-Office Link",
      CAPTURE_LINK: "Case Capture Link",
      T_AND_B_DESC: "Unsanitized T&B Description for KMS Reference",
      CONFIDENTIAL_DESC: "The case is marked confidential, no further information to be shared.",
      BUSINESS_TAGGING: "Administrative & Reporting Tags",
      SUBJECT_TAGGING: "Search and Discovery Tags",
      OFFICE_AUTO_GEO_TAG_WARNING: "Please ensure to click SAVE for auto-tagging of GEO tags under Business tags."
    },
    STEP_4_FIELDS: {
      PROPOSAL_RECEIVED: "Proposal Received",
      CURATED: "Curated",
      SEARCH_ASSETS_LABEL: "Search and Link existing Case Vignettes or Case materials using Material ID/URL",
      SEARCH_ASSETS_PLACEHOLDER: "Search",
      CASE_MATERIALS: "Case Materials",
      CASE_VIGNETTES: "Case Vignettes",
      NOTHING_SEARCHED: "Please enter a Title, Filename, ID, or paste the URL",
      CP_ADD_NEW_KP: "ADD A NEW KNOWLEDGE PAGE",
      ADD_KP_MODAL_HEADING: "Adding a New Knowledge page will take you to KP creation process.",
      ADD_KP_MODAL_SUBHEADING: "The page will get uploaded, but will be visible only after it's reviewed.",
      ADD_KP_MODAL_BUTTON: "PROCEED",
    },
    STEP_2_FIELDS: {
      RELATED_CASES: "RELATED CASE PAGES",
      SEARCH_RELATED: "Search Related Case Pages",
      SEARCH_PLACEHOLDER: "Search using case number (minimum 5 numeric are required)",
      REFINE_SEARCH: "Your search resulted in more than 25 records. Please refine and try again.",
      NO_RESULTS: "No results found.",
      RESULTS_HEADING: "Related Case Pages",
      SEARCH_VALIDATION_MESSAGE: "Please enter a minimum of 5 numerics for search",
      DELETE_TITLE: "De-link",
      DEFAULT_CASE_TYPE: "Select Case Type",
      CASE_LISTING: {
        CASE_ID: "Case ID",
        CASE_TITLE: "Case Title",
        TYPE: "Type",
        OPENED_DATE: "Opened Date",
        CLOSED_DATE: "T&B Closed Date",
        CASE_TYPE: {
          ID: "Id",
          NAME: "Name",
          VALUE: "value",
          KEY: 'key',
          TEXT: "text"
        }
      }
    },
    YES: "Yes",
    NO: "No",
    ALLOCATION_LISTING: {
      INDUSTRY_PA: "INDUSTRY PA",
      NO_ALLOCATION: "No Allocation Available",
      FUNCTIONAL_PA: "FUNCTIONAL PA",
      TOPIC_LEVEL_ALLOCATION: "TOPIC LEVEL ALLOCATION",
      ALLOCATION_PERCENT: "ALLOCATION (%)",
      GENERAL_ALLOCATION: "GENERAL ALLOCATION",
      FPA_STATUS: "FPA Status",
      READY_FOR_ALLOCATION: "Ready for allocation",
      BCG_REVENUE_FLAGS: "BCG REVENUE FLAGS",
      PIPE: "PIPE",
      BCG_TURN: "BCG Transform",
      DIGITAL_ANALYTICS: "Digital & Analytics",
      SAP: 'SAP',
      OVERLAY_TOPIC_GLOBAL_ADVANTAGE: "OVERLAY TOPIC - GLOBAL ADVANTAGE",
      OVERLAY_TOPIC_SOCIAL_ADVANTAGE: "OVERLAY TOPIC - SOCIAL IMPACT",
      OVERLAY_TOPIC_ClIMATE_SUSTAINABILITY: "OVERLAY TOPIC - CLIMATE & SUSTAINABILITY"
    },
    CASE_NUMBER: "CASE NUMBER",
    TB_BILLING: "T&B BILLING (LOCAL 000s)",
    TB_BILLING_IN_DOLLAR: "T&B BILLING ($)",
    TB_CLOSE_DATE: "T&B CLOSE DATE",
    BUDGET: "BUDGET (LOCAL 000s)",
    DATE_OPENED: "DATE OPENED (000s)",
    EXPECTED_END_DATE: "EXPECTED END DATE",
    CCO: "CCO",
    UNSANITIZED_CASE_TITLE: "UNSANITIZED CASE TITLE",
    CAPTURE_STATUS: "Case Capture Status",
    T_AND_B_ERROR: "Oops, something went wrong!",
    CASE_SAM_ERROR: "SAM Oops, something went wrong!",
    BILLING_PARTNER: "BILLING PARTNER",
    EMPTY_VALUE: "-",
    INVALID_DATE: "Invalid date",
    SAVING: "Saving...",
    WITHDRAWN: "Withdrawn",
    SAVED_SUCCESS_TITLE: "Case record saved successfully",
    SAVED_SUCCESS_MSG: "All the changes made by you on the case have been now saved.",
    SAVING_TEXT: "Saving...",
  },
  DURATIONS: {
    MONTH: 'Month',
    MONTHS: 'Months',
    YEAR: 'Year',
    YEARS: 'Years',
    FROM: 'From',
    TILL: 'Till',
    DAYS: 'Days',
    DAY: 'Day',
  },
  KB: {
    KB_TC_NEW_PUBLISHED_MESSAGE: "Your Knowledge material is queued for publishing and will be live in few seconds.",
    KB_PUBLISHED_MESSAGE: "Your Knowledge Bundle has been Published",
    KB_UNPUBLISHED_MESSAGE: "Your Knowledge Bundle has been Unpublished",
    KB_ARCHIVED_MESSAGE: "Your Knowledge Bundle has been Archived",
    TC_ARCHIVED_MESSAGE: "Your Topic Collection has been Archived",
    TC_PUBLISHED_MESSAGE: "Your Topic Collection has been Published",
    TC_UNPUBLISHED_MESSAGE: "Your Topic Collection has been Unpublished",
    CONFIRM_MSG_KB: "Are you sure you want to publish this Knowledge Bundle?",
    CONFIRM_MSG_TC: "Are you sure you want to publish this Topic Collection?",
    SECTION_SEARCH_HEADING: "Search and Discovery Tags",
    SEARCH_TAGS_HEADING: "Tags to assist search/discovery of this asset by users",
    TYPESEARCH_PLACEHOLDER: "Search tags"
  },
  KP: {
    HOME: "Home",
    CONTRIBUTIONS: "CONTRIBUTIONS",
    KP_NEW_PUBLISHED_MESSAGE: "Your Knowledge material is queued for publishing and will be live in few seconds.",
    PUBLISHED_MESSAGE: "Your Knowledge Page has been Published",
    ARCHIVED_MESSAGE: "Your Knowledge Page has been Archived",
    UNPUBLISHED_MESSAGE: "Your Knowledge Page has been Unpublished",
    REVIEW_MESSAGE: "Your Knowledge Page has been sent for Review.",
    PAGE_TITLE: "Create new knowledge page",
    EDIT_PAGE_TITLE: "Edit knowledge page",
    REQUIRED_LABEL: "All fields are required",
    PREVIEW_HEADING: "Quick view of the attachment",
    PREVIEW_MESSAGE: "Preview not supported",
    PREVIEW_TOOLTIP: "Preview is supported for following types of URL: Widen, Brightcove",
    PREVIEW_LAUNCHING: "Preview feature launching soon!",
    NOTIFICATION_MESSAGE: "Please complete highlighted fields",
    PILL_SAVING: "Saving...",
    PILL_DRAFT_SAVED: "Draft Saved",
    PUBLISH_QUESTION: "Are you sure you want to publish this Knowledge Page?",
    LOCKED_PUBLISH: "Knowledge page under draft by",
    REVIEW_QUESTION: "Are you sure you want to send this Knowledge Page for Review?",
    PUBLISH_REQUIRED_FIELDS_WARNING_MSG: "There are one or more incomplete fields on below steps. Please click on corresponding step to update.",
    REVIEWER_CONTENT: "You are the reviewer of this content",
    PILL_PUBLISHED: "Published",
    PILL_UNPUBLISHED: "Unpublished",
    PILL_REVIEW: "REVIEW",
    AUTHOR_LISTING_LABEL: "Authors",
    SANITIZATION_TITLE: 'Please ensure this document is sanitized and approved by an MDP before sending for review.',
    STEP3: {
      SELECTPA_TIPTEXT: "Respective Sector/Topic owners are notified of changes to this Knowledge Page",
      ADMIN_DESIGNATEPA_HEADING: "Select to designate ONE Sector/Topic as the OWNER",
      DESIGNATEPA_TIPTEXT: "This Sector/Topic owner will make decisions regarding revision/archive of this page",
      OFC_HEADING: "BCG Office",
      BCG_INTERNAL_OFC_HEADING: "BCG Office, for which content is ",
      ADMIN_ASSOCIATEDOFC_PLACEHOLDER: "Choose BCG office",
      COUNTRIES_HEADING: "Impacted Region/Country",
      GLOBAL_TEXT: "Global",
      OTHERS_TEXT: "Others",
      NO_MATCHED_REGIONS_COUNTRY_LINE1: "0 Results.",
      NO_MATCHED_REGIONS_COUNTRY_LINE2: "Your search returned no matches.",
      COUNTRIES_MAXLIMIT: "You can add maximum <configuredmaxlimit> Regions / Countries.",
      LANGUAGE_HEADING: "Language of the content",
      RESTRICTED_HEADING: "Restricted to MDP/Partner/Principal view",
      RESTRICTED_YES: "Yes",
      RESTRICTED_NO: "No",
      CMP_HEADING: "Is this Knowledge Page related to CMP?",
      GTM_HEADING: "Is this Knowledge Page related to GTM(Go to Market)?",
      RADIO_YES: "Yes",
      RADIO_NO: "No",
      ADDITIONAL_URL: "Additional URLs",
      NUDGES: {
        INTERNAL_OFFICE_LOCATION: 'Multiple applicable offices should be selected for BCG Internal materials'
      }
    },
    STEP2: {
      CASE_NUMBER_HEADING: "Case Number",
      CASE_NUMBER_PLACEHOLDER: "Add case number",
      CASE_NUMBER_TIPTEXT: "Adding a case number will auto populate the MDP Name(s)",
      MDP_NAME_HEADING: "Billing Partner/MDP associated with the case",
      AUTHORS_HEADING: "Authors",
      ORIGINAL_REQUESTOR: "Original Requestor",
      LINKING_VALIDATION_OWNER: "Knowledge Page Owner name is missing in Authors field. Please add the same.",
      NOCONTENT: "Content not available for this case code",
      INVALIDCASE: "Please enter the valid Case Number.",
      DUPLICATE_CASE: "already exists in related case number list",
      ADDITIONAL_CONTACTS: "Additional Contacts",
      AUTHORS_SUGGESTED_TITLE: 'Authors suggested based on case number added'
    },
    STEP1: {
      ADDFILE_HEADING: "Upload your file",
      KNOWPAGE_TYPE_HEADING: "Knowledge Page Type",
      RESTRICTED_YES: "Yes",
      RESTRICTED_NO: "No",
      ADMIN_SELECTPA_HEADING: "Associated PA/Topic/Sectors (Max 6 tags)",
      SECTION_SEARCH_HEADING: "Search and Discovery Tags",
      SEARCH_TAGS_HEADING: "Keywords",
      SECTION_ADMIN_HEADING: "Business Tags",
      TYPESEARCH_PLACEHOLDER: "Search tags",
      IS_RELATED_TO_CASE: "Is the document related to a case",
      APPROVED_BY_MDP: "Is this Content approved by MDP?",
      APPROVED_MDP_YES: "Yes",
      APPROVED_MDP_NO: "No",
      APPROVED_MDP_NO_INFO: "KMS team will verify compliance mandates before your contribution is live on Navigator.",
      ADDURL_HEADING: "Add URL",
      ADDFILE_BUTTON: "Browse file",
      REPLACEFILE_BUTTON: "Replace file",
      UPLOAD_ERROR: "Oops, something went wrong",
      UPLOAD_ERROR_TITLE: "File upload was unsuccessful!",
      UPLOAD_FILE_PLACEHOLDER: "Filenames with comma (,) semicolon (;) will be automatically replaced by underscore (_)",
      UNSUPPORTED_FILE_FORMAT: "Unsupported file format",
      UNSUPPORTED_FILE_TOOLTIP: "Supported file formats: PowerPoint, PDF, Word, Excel, Text, Images, HTML",
      ADDFILE_STATUS: "Your file is now being uploaded...",
      TITLE_HEADING: "Title",
      DESC_HEADING: "Description",
      PASTE_ADDITIONAL_URL_HERE: "Paste Additional URL here",
      CASE_EXIST_ERROR_MSG: "Case already exist in the list",
      CREATED_BY_HEADING: "Created By",
      TOOLTIPS: {
        ADD_URL: "Unacceptable URLs are – ",
        ADD_URL_POINTS_1: " a. URL of a Knowledge Page",
        ADD_URL_POINTS_2: " b. URL of a Knowledge Bundle"
      },
      KP_TITLE_PLACEHOLDER_TEXT: "Minimum <lv> characters required",
      BST_SUGGESTION_HEADING: "Administrative & Reporting Tags suggested based on case number added",
      BST_SUGGESTION_MODALHEADING: "Suggestions based on case number added",
      SUBJECT_SUGGESTION_MODALHEADING: "suggestions based on file uploaded",
      SUBJECT_SUGGESTION_HEADING_BY_FILEUPLOAD: "AI powered Keyword suggestions are based on uploaded file content",
      SUBJECT_SUGGESTION_HEADING: "Search and Discovery Tags as per case number",
      SELECT_PLACEHOLDER: "Select",
      BCG_INTERNALS_KP_TYPE_MESSAGE: "Content will be available under 'BCG Internal' tab in Navigator Search",
      MAXIMUM_TAGS_SELECTED: "Max 6 PA/Topic Tags can be added. Related shared topics are considered as single tag.",
      UNIQUE_TITLE_ERROR_MSG: "Title already in use for another Knowledge Page",
      MAXIMUM_SUBJECT_TAGS_MSG: "Minimum 3 keywords should be added",
      NUDGES: {
        FIVE_WORDS_LIMIT: '5 words would make a good Title!',
        IMPROVE_FINDABILITY: 'Add a bit more context to improve findability!',
        SEARCHABLE_WORDS: 'Searchable words: ',
        IMPROVE_SEARCHABILITY: 'Add keywords in your Knowledge Page title to make it appear higher in search results. Some examples of keyword are "agile," "employee benefits" etc.',
        KEYWORDS_LIMIT_FOR_BETTER_CONTEXT: '4-8 keywords provide better context!',
        UPLOAD_FILE_SUBJECT_SUGGESTION_MSG: 'Add more relevant content to the uploaded file to get Keyword Suggestions'
      },
      SEARCH_RELATED: "Case Number",
      CASE_TOOLTIP: "Enter case number",
      SEARCH_PLACEHOLDER: "Search using case number (minimum 5 numeric are required)",
      NO_RESULTS: "No results found.",
      REFINE_SEARCH: "Your search resulted in more than 25 records. Please refine and try again.",
      RESULTS_HEADING: "Related Case Pages",
    },
    DOWNLOAD_PAGE_TITLE: "KP Download",
    STEPS: {
      STEP1: "STEP 1",
      STEP2: "STEP 2",
      STEP3: "STEP 3",
    }
  },
  TOOLTIPS: {
    FOOTER_CLONE: 'Select Clone to copy all the page information already provided (e.g., description, tagging, authors, etc.). Your Knowledge Page will be cloned in a new draft with the prefix "Copy of".',
    KP_TYPE: 'for guidance on how to select the best Knowledge Page Type for your asset:',
    KP_CASENUMBER: "Enter case number and click '+' button on the right to validate.",
    KP_TITLE_1: "Name your page. Titles must contain at least 15 characters.",
    KP_TITLE_2: "Remember, title is the first thing users notice:",
    KP_DESC_1: "It is recommended to have at least two paragraphs that clearly describe the asset.",
    KP_DESC_2: "Description appears in the body of the Knowledge Page:",
    KP_BST_1: "You can only select up to 6 PA Sectors/Topics.",
    KP_BST_2: "Choose only the sectors who are 'key contributors' of this knowledge page. You can also choose specialty businesses (e.g. Gamma, Platinion etc.) or centers or programs as needed.",
    KP_BST_CUST_1: "Custodian sector/topic is responsible for the review, update and maintenance of the knowledge page.",
    KP_BST_CUST_2: "You must actively select ONE sector or topic as the owner, even if only one is listed, by clicking on it below.",
    KP_SUBJECT_1: "Search and select the keywords that best describe the subject content of the knowledge page.",
    KP_SUBJECT_2: "Note: keywords help improve the search and findability of the knowledge page. You may choose as many keywords as required to represent the subject context of the knowledge page. The subject hierarchy represent terms from different subject areas, and it does not represent a PA or PA structure.",
    KP_MDP_1: "This field will only appear if the Knowledge Page is related to a case (as indicated in prior step).",
    KP_MDP_2: "Type first 3 characters of the First/Last Name to trigger a search.",
    KP_AUTHORS_1: "These are the individual(s) who created the content.",
    KP_AUTHORS_2: "Type first 3 characters of the First/Last Name to trigger a search.",
    KP_ADDITIONAL: "Additional contacts who have not authored the asset, however, can provide guidance about the content/associated topic. These could be Content Managers, KT Contacts, etc.",
    KP_OFFICE: "For case related content, usually is the case office and for non-case related content, usually is the office of the authors",
    KP_REGION_COUNTRY: "Select the country/geography the page is relevant to.",
    KP_ADDITIONAL_URL: "URLs of BCG websites, microsites, external sites that are related to this content",
    KP_UPLOADFILE: "You can upload one asset per Knowledge Page.",

  },
  BUTTONS: {
    ADDITIONAL_INFO: "ADDITIONAL INFO",
    CONTRIBUTE_KNOWLEDGE: "CONTRIBUTE KNOWLEDGE",
    CLONE: "CLONE",
    CLONE_CONTENT: "Clone Content",
    NEXT: "Next",
    BACK: "Back",
    PREVIEW_PAGE: "Preview Page",
    PREVIEW_BUNDLE: "Preview Bundle",
    PUBLISH: "Publish",
    SAVEASDRAFT: "Save as Draft",
    CREATE: "Create",
    CANCEL: "Cancel",
    ADD: "Add",
    SAVE: "Save",
    DELETE: "Delete",
    EDIT: "Edit",
    SEND_REVIEW: "Send For Review",
    VIEW_CONSUMPTION: "VIEW CONSUMPTION",
    CONTRIBUTE_MORE: "CONTRIBUTE MORE",
    HOME: "Home",
    XMC_HOME: "VIEW STATUS",
    NEW_BUNDLE: "New bundle",
    CONFIRM: "Confirm",
    SHARE: "Share",
    SHARE_VIA_EMAIL: "Share Via Email",
    COPY_URL: "Copy URL",
    COPY_URL_DONE: "Copied",
    CONTRIBUTE_NEW_KNOWLEDGE: "CONTRIBUTE NEW KNOWLEDGE",
    OK: 'OK'
  },
  TARGET: {
    SELF: "_self",
    BLANK: "_blank"
  },
  KNOWLEDGE_PRODUCT_NAME: {
    // TODO: Move it to React.CONTEXT
    TPKB: "TPKB",
    KP: "KP",
    ALL: "ALL",
    MY_NAVIGATOR: "Navigator",
    CREATION: "Creation",
    CP_CONSUMPTION: "CP Consumption"
  },
  SCREEN_NAMES: {
    ADD_CONTENT: "add-content",
    ADD_METADATA: "add-metadata",
    WORKSPACE: "workspace",
  },
  KB_FORM_PLACEHOLDERS: {
    ADD_TITLE: "Add Title for your bundle",
    SEARCH_KP: "Search for knowledge pages with ID or URL",
    TITLE_SEARCH: "Add Knowledge pages with ID or URL",
    KB_ASSETS_VALIDATION_ONLYTEXT: "Please add atleast 2 Knowledge assets to build a bundle",
    KB_ASSETS_VALIDATION: "*Please add atleast 2 Knowledge assets to build a bundle",
    KB_TITLE_PLACEHOLDER_TEXT: "Minimum <lv> characters required",
  },
  ALT_TEXTS: {
    ENTER_ICON: "Click here on Enter Icon on search the Material",
    DELETE_ICON: "Delete Icon",
    ENTER_ICON_URL: "Enter Additional Url",
    ADD_URL: "Add url",
    CROSS: "Cross",
    ADD_CASE_CODE: "Add case code",
    SEARCH_ICON: "Search",
    MORE_OPTIONS: "More Options",
    SUGGESTION_LIST_INFOTEXT: "Couldn't find what you were looking for? Try refining your search keyword",
    LOCATION_ICON: "Case Office Location",
    KNOWLEDGE_ASSETS: "Knowledge Assets",
    NO_KNOWLEDGE_ASSETS: "No Knowledge Assets",
    NEXT_STEP: "Proceed to Step 3 - Select Tags",
    CANCEL_MODAL_ALT: "Cancel the Image Picker modal"
  },
  TABS_ADD_ITEMS: {
    KNOWLEDGE_PAGES: "Knowledge Pages",
    SUPPORTING_URLS: "Supporting URLs",
  },
  WORK_SPACE: {
    PAGE_TITLE: "Workspace",
    TOTAL_ITEMS: "Total items",
    DRAFTS: "Drafts",
    PUBLISHED: "Published",
    KNOWLEDGEHEADING: "Topic Page Knowledge Bundle",
    DATEHEADING: "Date Updated",
    DATEPUBLISHED: "Date Published",
    ACTIONHEADING: "Action",
    WARNING_MODAL_TITLE: "Are you sure you want to Edit Published Bundle?",
    WARNING_MODAL_CANCEL: "Cancel",
    WARNING_MODAL_EDIT: "Edit",
    WARNING_MODAL_CONFIRM: "Confirm",
    EDIT_API_ERROR_TITLE: "Oops, something went wrong",
    EDIT_API_ERROR_TEXT: "Sorry, You request for editing was unsuccessful!",
    WARNING_MODAL_INPUT_PLACEHOLDER: "Summary of changes done (Optional)"
  },
  METADATA: {
    ADDITION_CONTRIBUTORS: "Additional Contributors",
    ORIGINAL_REQUESTOR: "Original Requestor",
    COVER_IMAGE: "Select cover image",
    COVER_IMAGE_BUTTON: "Select",
    COVER_IMAGE_BUTTON1: "Update",
    TC_COVER_TITLE_PLACEHOLDER: "Your Title will come here...",
    TC_COVER_DESC_PLACEHOLDER: "Your Description text will come here...",
    COVER_IMAGE_PLC_TEXT: "Your collection title will appear here...",
    COVER_PIC_TITLE: "Select Cover Image",
    COVER_PIC_SUBTITLE: "Please select an image for displaying as a cover for the collection here.",
    TC_DATE_PLACEHOLDER_TEXT: "Date",
    TC_FILES_PLACEHOLDER_TEXT: "Number of files",
    CREATED_BY: "Created By",
    DESCRIPTION: "Description",
    BUNDLE_TITLE: "Bundle Title",
    MANDATORY_FORM_FIELD_TEXT: "All fields are required",
    CONTRIBUTORS_MAXLIMIT_MSG:
      "You can add maximum of 10 contributors in additional contributors field.",
    MULTIPLE_TOPIC_PAGES_MAXLIMIT_MSG:
      "You can add maximum <configuredmaxlimit> PA/Topic Pages. Related shared topics are considered as single tag.",
    MULTIPLE_TOPIC_PAGES_MAXLIMIT_MSG_TITLE: "Limit reached",
    CREATED_BY_MAXLIMIT_MSG:
      "You can add maximum of 1 person in created by field.",
    PEOPLE_SELECTOR_PLACEHOLDER: "Search by First/Last name",
    FACECARD_PEOPLE_SELECTOR_EMAIL: "Add multiple valid email ids separated by ';' (semi-colon)",
    FACECARD_PEOPLE_SELECTOR_PLACEHOLDER: "Search by People",
    ADMINISTRATIVE_TITLE: "Business Tags",
    SECTOR_OWNER_TITLE: "Select to designate ONE Sector/Topic as the OWNER"
  },
  TAXONOMY: {
    TOPIC_LABEL:
      "Which PA/Topic Page would you like to display this Bundle on?",
    TOPIC_PLACEHOLDER: "Please add PA/Topic Page",
    TOPIC_MODAL_HEADING:
      "Which PA/Topic Page would you like to display this Bundle on?",

    IPA_FPA_LABEL: "Select PA Sectors/Topics",
    IPA_FPA_TIPTEXT: "Respective Sector/Topic owners are notified of changes to this Knowledge Bundle",
    IPA_FPA_PLACEHOLDER: "Select PA Sectors/Topics",
    IPA_FPA_MODAL_HEADING: "Select PA Sectors/Topics",
    IPA_FPA_MODAL_SEARCH_PLACEHOLDER: "Search Practice area",

    SUBJECT_LABEL: "Search and Discovery Tags",
    SUBJECT_PLACEHOLDER: "Eg: Industrial Goods",
    SUBJECT_MODAL_HEADING: "Search and Discovery Tags",
    SEARCH: "Search",
    SEARCH_SUBJECT_PLACEHOLDER: "Search Subject",
    SECTION_ADMIN_HEADING: "Administrative & Reporting Tags",
    TYPESEARCH_PLACEHOLDER: "Search tags",
    AUTOTAGGED_MESSAGE: "Tag added as the content is associated to a specific Geo Knowledge Team, as per case number.",
    AUTOTAGGED_MESSAGE_CASES: "Tag added as per Client and Client Business Unit.",
    NORESULT_TYPEAHEAD_HEADING: "There are no relevant business tags available for this keyword!",
    TOOLTIPS: {
      IPA_TIP_MESSAGE: "IPA tagging is not available as Internal KP type is selected",
      FPA_TIP_MESSAGE: "FPA tagging is not available as Internal KP type is selected",
      POLY_HIERARCHI_CUSTODIAN_MSG: "Shared topic cannot be selected as Custodian, please choose a non-shared topic.",
      POLY_HIERARCHI_TAXONOMY_SUGGESTION_MSG: "Shared topic, additional tag will be auto applied/removed.",
      TAG_SPECIFIC_TO_GEO_KNOWLEDGE_TEAM: "Tag added as the content is associated to a specific Geo Knowledge Team, as per case number.",
      TAG_SPECIFIC_FOR_CLIENT_AND_BUSINESS_UNIT: "Tag added as per Client and Client Business Unit.",
      TAG_SPECIFIC_TO_GEO_AUTO_TAG: "Tag added as the content is associated to a specific Geo Knowledge Team, as per case office.",
      CUSTODIAN_POLY_MSG: "Shared Topic;",
      CUSTODIAN_POLY_MSG_2: "Shared Topic",
      TAG_SPECIFIC_TO_REVENUE_ALLOCATION_TAG: "Tag added as per Overlay Topic revenue allocation",
      TAG_SPECIFIC_TO_BCG_TRANSFORM_TAG: "Tag added as per BCG Transform Revenue flag value",
      TAG_SPECIFIC_TO_BCG_PIPE_TAG: "Tag added as per BCG PIPE Revenue flag value"
    }
  },
  HELP_MODAL: {
    heading: "Steps to Add Materials to a Bundle",
    subheading: "Please use the following steps to add materials to a bundle",
    announcementText: {
      message: "We recommend best practice of adding 3-5 materials per Bundle.", message1: "We are working on making it easier to add materials. Coming soon!",
    },
    steps: [
      { stepmsg: "Step", message: "Enter the Material ID to search <br />If you don’t have the ID, then use the following steps to find Material page URL" },
      { stepmsg: "Step", message: "Navigate to ", message_link: "Smart Search", link_destination: "http://smartsearch.bcg.com" },
      { stepmsg: "Step", message: "Enter a search term using title::< Material Title >" },
      { stepmsg: "Step", message: "Review the search results and locate the specific result you are looking for." },
      { stepmsg: "Step", message: "Click on the share icon of the result and select &#34;Link To Material Page&#34;" },
      { stepmsg: "Step", message: "Come back to this page, paste the copied link and search. Add the material and locate the next material using the steps above." },
    ]
  },
  WORKFLOW_TITLES: {
    KP: {
      STEP1_TITLE: "Upload & Describe",
      STEP2_TITLE: "Authors & Contacts",
      STEP3_TITLE: "Additional Information",
    },
    TPKB: {
      STEP1_TITLE: "Describe Bundle",
      STEP2_TITLE: "Select Items",
      STEP3_TITLE: "Select Tags",
    },
  },
  CREATION: {
    PAGE_TITLE: "Creation",
    CREATE_KNOWLEDGE_PAGE_BTN_HEADING: "CREATE A KNOWLEDGE PAGE",
    CREATE_KNOWLEDGE_PAGE_TIPTEXT: "Create a Knowledge Page",
    CREATE_ASSOCIATE_BUNDLE_BTN_HEADING: "CREATE A BUNDLE WITH A PA/TOPIC PAGE",
    CREATE_ASSOCIATE_BUNDLE_TIPTEXT: "Create a Knowledge Bundle by associating PA/Topic Page where this bundle will be display",
    CREATE_KNOWLEDGE_BUNDLE_BTN_HEADING: "CREATE A KNOWLEDGE BUNDLE",
    CREATE_KNOWLEDGE_BUNDLE_TIPTEXT: "Create a Knowledge Bundle",
    EDIT_CASE_PAGE_BTN_HEADING: "EDIT A CASE PAGE",
    EDIT_CASE_PAGE_TIPTEXT: "Edit a Case Page",
    CREATION_PAGE_HERE_TO_TEXT: "I am here to...",
    CREATE_KNOWLEDGE_PAGE_WITH_FILE: "Create a Knowledge Page with a file",
    UPLOAD_ASSETS_TEXT: "Upload an asset like PowerPoint, PDF, Word, Image, Text file etc.",
    CREATE_KNOWLEDGE_PAGE_WITH_URL: "Create a Knowledge Page with a URL",
    TITLE_MIN_LENGTH_MESSAGE: "Minimum <lv> characters required",
    TITLE_MIN_WITH_WORDS_LENGTH_MESSAGE: "Minimum <lv> characters with 4 or more words are required for a good Title",
    TITLE_MAX_LENGTH_MESSAGE: "Maximum length should be <lv> characters",
    ADD_URL_TEXT: "Add a URL for BrightCove, widen, BCG.com articles, Microsites, External materials, etc.",
    TEXT_MIN_LENGTH_MESSAGE: "Minimum <lv> characters required",

  },
  CP_CONSUMPTION: {
    PAGE_TITLE: "My Navigator",
    CLIENT_NAME: "CLIENT NAME",
    CLIENT_DESCRIPTION: "CLIENT DESCRIPTION",
    CLIENT_BU: "CLIENT BU",
    CLIENT_BU_DESCRIPTION: "CLIENT BU DESCRIPTION",
    DESCRIPTION_HEADING: "Description",
    NO_DESCRIPTION_TEXT: "No Case Description Available",
    CONTACTS_HEADING: "Contacts",
    VIEW_LEGACY_KN: "View this page on legacy KN",
    LEGACY_KN: "Legacy KN",
    KNOWLEDGE_ASSETS: "Knowledge Assets",
    KNOWLEDGE_ASSETS_ASSOCIATED: "CASE VIGNETTES ASSOCIATED WITH THIS CASE",
    NO_ASSETS_ASSOCIATED: "NO CASE VIGNETTES ASSOCIATED WITH THIS CASE",
    CASE_VIGNETTES: "Case Vignettes",
    CASE_MATERIALS: "Case Materials",
    RELATED_CASES: "Related Case Pages",
    OPENDATE_HEADING: "Started On | ",
    CASE_TEAM: "Case Team",
    ASSOCIATED_PA_TOPIC_SECTOR: "Associated PA/Topic/Sector",
    WITHDRAWN_CASE_BANNER_HEADING: "Case Update",
    WITHDRAWN_CASE_BANNER_MESSAGE: "Case Page has been withdrawn",
    BILLING: "Billing",
    TB_BILLING: "T&B Billings (Local 000s)",
    TB_BILLING_USD: "T&B Billing ($)",
    BUDGET: "Budget (Local 000s)",
    KNOWLEDGE_PAGES_ASSOCIATED: "KNOWLEDGE PAGES ASSOCIATED WITH THIS CASE",
    NO_VIGNETTES: "NO CASE VIGNETTES ASSOCIATED WITH THIS CASE",
    NO_MATERIALS: "NO CASE MATERIALS ASSOCIATED WITH THIS CASE",
    EDIT_PAGE: "Edit Page",
    CASE_TAGGED_ITSELF: "A case cannot be tagged to itself",
    CASE_TAGGED_IN_LIST: "This case page already exists in the below-related case page list"
  },
  MY_NAVIGATOR: {
    PAGE_TITLE: "My Navigator",
    TAB_MY_CONTRIBUTIONS: "Contributions",
    TAB_MY_ACTIVITIES: "Activities",
    TAB_MY_CASES: "My Cases",
    SEARCH_PLACEHOLDER: "Search",
    ENTITY_TYPES: {
      KB: "Knowledge Bundle",
      KP: "Knowledge Page",
      PA: "PA Page",
      TC: "Topic Collection",
      TCS: "Topic Collections"
    },
    SHOW: "Show",
    ITEM_STATUS: {
      DRAFT: "draft",
      PUBLISH: "publish"
    },
    STATUS_FILTERS: {
      ALL: "All",
      DRAFT: "Drafts",
      PUBLISHING_QUEUE_MSG: "Recently published knowledge materials will start appearing in the Published list within few minutes. If you are unable to find your recently published material, please refresh your browser.",
      REVIEW: "Under Review",
      PUBLISHED: "Published",
      ARCHIVED: "Archived",
      UNPUBLISHED: "Unpublished",
      WITHDRAW: "Withdraw",
      PA_RECOMMDS: "PA Recommendations",
      NO_RESULTS: "No Results" // temp pill
    },
    SORT: "Sort By",
    SORT_FILTERS: {
      DATE_DESC: "Date Descending",
      DATE_ASC: "updatedDate"
    },
    PAGING_SPACER: "...",
    BUTTONS: {
      WARNING_MODAL_EDIT: "Edit",
      WARNING_MODAL_OK: "OK",
      WARNING_MODAL_CONFIRM: "Confirm",
      WARNING_MODAL_DELETE: "Delete",
      WARNING_MODAL_CANCEL: "Cancel",
      WARNING_MODAL_UNPUBLISH: "Unpublish"
    },
    ENTITY_ACTIONS: {
      LOCKED: "This page is locked for editing by",
      LOCKED_DRAFT: "This page is locked as new version created in Draft by",
      LOCKED_DRAFT_BUNDLE: "This bundle is locked as new version created in Draft by",
      LOCKED_DRAFT_COLLECTION: "This collection is locked as new version created in Draft by",
      LOCKED_BUNDLE: "This bundle is locked for editing by",
      LOCKED_COLLECTION: "This collection is locked for editing by",
      LOCKED_ALT: "Locked",
      EDIT_ALT: "Edit",
      DELETE_ALT: "Delete",
      PREVIEW_ALT: "Preview",
      ARCHIVE_ALT: "Archive",
      UNPUBLISH_ALT: "Unpublish",
      EDIT_PAGE: "Edit Page",
      ARCHIVE_PAGE: "Archive Page",
      MOVE_TO_DRAFT: "Move to Draft",
      ADMIN_HISTORY: "Admin History",
      CONTINUE_EDITING: "Continue Editing",
      CLONE: "Clone",
      ADDITIONAL_INFORMATION: "Additional Information",
      CONTRIBUTE_KNOWLEDGE: "Contribute Knowledge",
      MY_NAVIGATOR: "My Navigator"
    },
    ENTITY_ACTIONS_MODAL_TITLES: {
      EDIT_MODAL_TITLE_KB: "Are you sure you want to Edit Published Bundle?",
      EDIT_MODAL_TITLE_KP: "Are you sure you want to Edit Published Page?",
      EDIT_MODAL_TITLE_TC: "Are you sure you want to Edit Published Collection?",

      DELETE_MODAL_TITLE_KB: "Are you sure you want to delete this bundle?",
      DELETE_MODAL_TITLE_TC: "Are you sure you want to delete this collection?",
      DELETE_MODAL_TITLE_KP: "Are you sure you want to delete this page?",

      UNPUBLISH_MODAL_TITLE_KB: "Are you sure you want to unpublish this bundle?",
      UNPUBLISH_MODAL_TITLE_TC: "Are you sure you want to unpublish this collection?",
      UNPUBLISH_MODAL_TITLE_KP: "Are you sure you want to unpublish this page?",

      MOVETODRAFT_UNPUBLISHED_MODAL_TITLE_KB: "Are you sure you want to move this Unpublished bundle to Draft?",
      MOVETODRAFT_UNPUBLISHED_MODAL_TITLE_TC: "Are you sure you want to move this Unpublished collection to Draft?",
      MOVETODRAFT_UNPUBLISHED_MODAL_TITLE_KP: "Are you sure you want to move this Unpublished page to Draft?",

      MOVETODRAFT_ARCHIVED_MODAL_TITLE_KB: "Are you sure you want to move this Archived bundle to Draft?",
      MOVETODRAFT_ARCHIVED_MODAL_TITLE_TC: "Are you sure you want to move this Archived bundle to Draft?",
      MOVETODRAFT_ARCHIVED_MODAL_TITLE_KP: "Are you sure you want to move this Archived page to Draft?",

      ARCHIVE_MODAL_TITLE_KB: "Are you sure you want to archive this bundle?",
      ARCHIVE_MODAL_TITLE_TC: "Are you sure you want to archive this bundle?",
      ARCHIVE_MODAL_TITLE_KP: "Are you sure you want to archive this page?",

    },
    NO_RESULT_TITLE: "No results matching your search",
    NO_RESULT_CASE_TITLE: "No cases found. Please try using different filter.",
    NO_RESULT_SUGESSTIONS: "Suggestions:<br />- Try more general keywords<br />- Remove filters to broaden search<br />- Try different keywords<br />- Make sure all words are spelled correctly",
    VERSION: "Version",
    DOWNLOADS: "Downloads",
    EMPTY_VALUE: "-"
  },
  ADDITIONAL_URLS: {
    ADDITIONAL_URLS_TAB_HEADING: "Additional URLs"
  },
  ADDITIONAL_LABELS: "Impact on search",
  IMPACT_ON_SEARCH: {
    MEDIUM: "Medium",
    HIGH: "High"
  },
  LOADING: "LOADING....",
  LOADING_ICON_ALT: "Loading icon",
  URL: "URL",
  GLOBAL: {
    RESTRICTED: "restricted",
    INCOMPLETE_FORM_NOTIFICATION_MESSAGE: "Please complete highlighted fields",
    YES: "YES",
    NO: "NO",
    ALUMNI: "Alumni",
  },
  ERROR_MESSAGES: {
    INVALID_DATE: 'Please pass in a valid date',
    DOWNLOAD_ERROR: "Oops, something went wrong"
  },
  CASE_CONTACTS: {
    DATA_KEYS: {
      NAME: "preferredName",
      POSITION: "position",
      HOURS: "hours",
      ALUMNI: "alumni",
      HRID: "hrEmployeeId"
    },
    HEADERS: {
      NAME: "Name",
      POSITION: "Case Position",
      HOURS: "Hours",
    },
    HOURS_TOOLTIP_MESSAGE: "The hours displayed are only an indicative reference since they do not include shadow/investment hours",
    VIEW_OTHER_CONTACTS: "VIEW OTHER CONTACTS",
    OTHER_CONTACTS_MODAL_HEADING: "Contacts",
    CCO: "CCO",
    BILLING_PARTNER: "BILLING PARTNER",
    KMS_OWNER: "KMS OWNER",
    EXECUTING_MDP: "EXECUTING MDP",
    CASE_EDITOR: "CASE EDITOR",
    CASE_TEAM_LIST_HEADING: "CASE TEAM",
    PRIMARY_CONTACT_LIST_HEADING: "PRIMARY CONTACT",
    ALUMNI_LIST_HEADING: "ALUMNI",
    ADDITIONAL_CASE_TEAM_HEADING: "ADDITIONAL CASE TEAM",
    NO_CONTACT_AVILABLE: "No contact available",
  },
  TOPICS: {
    INDUSTRIES: "Industries",
    FUNCTIONAL: "Functions",
    BCG_INTERNALS: "BCG Internal",
  },
  DISPLAY_MODE: {
    DOCVIZ: "docviz overlay"
  },
  ADMIN_HISTORY_SECTION: {
    AVAILABLE_CONSUMPTION: "(Available for consumption)",
    HEADER: "ADDITIONAL INFORMATION",
    FIELDS: {
      KB: 'KNOWLEDGE BUNDLE',
      TC: 'TOPIC COLLECTION',
      KP_FILE: 'FILE NAME',
      KP_URL: 'URL',
      DOWNLOADS: 'DOWNLOADS',
      CREATED: 'ORIGINAL PUBLISHED DATE',
      OFFICE: 'OFFICE',
      PUBLISHER: 'ORIGINAL PUBLISHER',
      TITLE: 'TITLE',
    },
    KEYS: {
      PERSON: 'person',
      DOWNLOADS: 'downloads',
      CREATED: 'originalPublishDate',
      OFFICE: 'office',
      TITLE: 'title',
      KB_TITLE: 'kb_title',
    },
    TABLE_KEYS: {
      DATE: "DATE",
      STATUS: "STATUS",
      CHANGED_BY: "CHANGED BY",
      VERSION: "VERSION",
      COMMENTS: "COMMENTS",
      UPDATED_FIELDS: "UPDATED FIELDS",
    }
  },
  IDENTIFIER: {
    CARD_DOCVIZ_OVERLAY_OPEN: 'CARD_DOCVIZ_OVERLAY_OPEN',
    CARD_PREVIEW_INTERACTIONS: 'CARD_PREVIEW_INTERACTIONS',
    CARD_CP_VIEW_OTHER_CONTACTS: 'CARD_CP_VIEW_OTHER_CONTACTS',
    Cases_DESCRIPTION_SHOW_MORE: 'Cases_DESCRIPTION_SHOW_MORE',
    Cases_DESCRIPTION_SHOW_LESS: 'Cases_DESCRIPTION_SHOW_LESS',
    CARD_CP_CASE_TEAM_MEMBER: 'CARD_CP_CASE_TEAM_MEMBER',
    CARD_CP_CCO: 'CARD_CP_CCO',
    CARD_CP_KMS_OWNER: 'CARD_CP_KMS_OWNER',
    CARD_CP_BILLING_PARTNER: 'CARD_CP_BILLING_PARTNER',
    CARD_CP_EXECUTING_MDP: 'CARD_CP_EXECUTING_MDP',
    CARD_CP_ALUMNI: 'CARD_CP_ALUMNI',
    CARD_ADDITIONAL_TEAM_MEMBER: 'CARD_ADDITIONAL_TEAM_MEMBER',
    CARD_AUTHOR: 'CARD_AUTHOR',
    CARD_EMAIL: 'CARD_EMAIL',
    CP_PRICING_IDENTIFIER: 'CP_PRICING_IDENTIFIER',
    CP_KN_LEGACY_IDENTIFIER: 'CP_KN_LEGACY_IDENTIFIER',
    CP_EDIT_IDENTIFIER: 'CP_EDIT_IDENTIFIER',
    CP_KNOWLEDGE_ASSETS_ACTIVE_IDENTIFIER: 'CP_KNOWLEDGE_ASSETS_ACTIVE_IDENTIFIER',
    CP_KNOWLEDGE_ASSETS_INACTIVE_IDENTIFIER: 'CP_KNOWLEDGE_ASSETS_INACTIVE_IDENTIFIER',
    CP_SEARCH_AND_DISCOVERY_TAGS_IDENTIFIER: "CP_Search and Discovery Tags_IDENTIFIER",
    CP_ASSOCIATED_PA_TOPIC_SECTOR_IDENTIFIER: "CP_Associated PA/Topic/Sector_IDENTIFIER",
  },
  INSUFFICIENT_PERMISSIONS: 'Insufficient Permissions',
  USD: "USD",
  UNCAUGHT_ERROR_MSG: 'Could not load this component :(',
  CASE_HISTORY_MODAL: {
    ROW_COUNT: 20,
    NO_CASE_HISTORY: "There are no past case history available at the moment",
    HEADER: "CASE HISTORY SECTION",
    TABLE_KEYS: {
      DATE: "DATE",
      CHANGED_BY: "CHANGED BY",
      UPDATED_FIELDS: "UPDATED FIELDS",
    }
  },
  FACE_CARD_GENERATOR: {
    PAGE_TITLE: 'Face Card Generator',
    HEADER_TEXT: 'Nail your style with perfectly sized thumbnail photos!',
    HI: 'Hi',
    FAQ: 'FAQ',
    FACE_CARD_SLIDE: 'Face card slide',
    TEMPLATE_MESSAGE: "Create Face Cards Templates",
    TEMPLATE_MESSAGE_2: "These Templates let you generate face cards as per your needs",
    FACE_CARD_WARNING_IMAGE: "Images may seem distorted below, high resolution images are available in the exported file.",
    SEARCH_RELATED: "Search Related Case Pages",
    SEARCH_PLACEHOLDER: "Search using case number (minimum 5 numeric are required)",
    REFINE_SEARCH: "Your search resulted in more than 25 records. Please refine and try again.",
    NO_RESULTS: "No results found.",
    RESET_WARNING_MESSAGE: "Are you sure you want to reset the list?",
    RESET_BTN_CONFIRM: "Confirm",
    USE_B_W: "Use Black and White",
    OTHER_INFO: "Other Informations to Include",
    RESET_BTN_TEXT: "RESET"
  },
  TOGGLE_TEXTS: {
    LARGE: "Large",
    MEDIUM: "Medium",
    SMALL: "Small"
  },
  FACE_CARD_SELECT_TEXTS: {
    BY_PEOPLE: "By People",
    BY_ID: "By ID",
    BY_CASE_NO: "By Case No.",
    BY_EMAIL_ID: "By Email ID"
  },
  FACE_CARD_FORM: {
    NAME: 'Name',
    TITLE: 'Title',
    LOCAITON: 'Location',
    WITH: 'With',
    SELECT_SIZE: 'Select Size',
    PEOPLE_SELECTOR_VALIDATION_TEXT: "Please complete the highlighted field(s)"
  },
  FACE_CARD_DEFAULT_VIEW: {
    stepper: [

      { message: 'Search' },
      { message: 'Build a List' },
      { message: 'Click Preview' },
      { message: 'Download' },

    ]
  },

  FACE_CARD_ERROR_PAGE: {
    MESSAGE_1: "We are currently experiencing a technical issue!",
    MESSAGE_2: "Please try after sometime.",
    POLLING_API_ERROR: "We are currently experiencing a technical issue!",
    REQUEST_API_ERROR: "We are currently experiencing a technical issue!"
  }
}

export default LABELS;

import React, { Fragment, PureComponent } from "react";
import { Container } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { FullPageLoader } from "components/shared/FullPageLoader";
import CONFIG from "config";
import { Notification } from "components/shared/Notification";
import { PageHeader } from "components/PageHeader";
import { ErrorBoundary } from "containers/ErrorBoundary/ErrorBoundary";
import { selectors as notifySelectors } from "redux/reducers/notification/notification_reducer";
import { selectors as globalSelectors } from "redux/reducers/global/global_reducer";
import "./Layout.scss";
import UsabillaSetup from "components/UsabillaSetup/UsabillaSetup";
import { usabillaSetupFn } from "../../utils/usabilla/usabilla.min.js";
import { SmartLogicSetup } from "components/SmartLogicSetup/SmartLogicSetup";
import { Entitlement401RedirectWrapper } from "containers/Entitlement401RedirectWrapper";
import { BCGHeader, BCGFooter } from '@kdshared/global-nav';
import { getUserId } from 'utils/auth/auth';
import { bindActionCreators } from "redux";
// Actions
import { actions as peopleResultsActions } from 'redux/actions/peopleresults/peopleresults_actions';
import { setLoggedInUserData } from "redux/global.action";
import { FaceCardGeneratorHeader } from "components/FaceCardGeneratorHeader/FaceCardGeneratorHeader";
import { FcgHeader } from "components/FcgHeader/FcgHeader";

export class LayoutContainer extends PureComponent {

  componentDidMount = () => {
    const { IS_WALKME_ENABLED } = CONFIG;
    if (IS_WALKME_ENABLED) {
      this.walkMeInitialize();
    }
    usabillaSetupFn();
    this.getLoggedInUSerDetails();
  }

  getLoggedInUSerDetails = async () => {
    const hrid = await getUserId();
    const {
      peopleResultsActions: {
        getPeopleDataUsingIDs,
      },
      setLoggedInUserData
    } = this.props;
    const peopleData = await getPeopleDataUsingIDs([hrid]);
    if (peopleData && peopleData[0])
      setLoggedInUserData(peopleData[0]);
  }

  walkMeInitialize = () => {
    if (window) {
      const { API_URL: { WALKME_SCRIPT_URL } } = CONFIG;
      const walkme = document.createElement('script');
      walkme.type = 'text/javascript';
      walkme.async = true;
      walkme.src = WALKME_SCRIPT_URL;
      const s = document.getElementsByTagName('script')[0];
      if (!!s) {
        s.parentNode.insertBefore(walkme, s);
      }
      window._walkmeConfig = { smartLoad: true };
    }
  }

  goHome = () => {
    const { history } = this.props;
    history.push(CONFIG.UI_URL.HOME);
  };

  render = () => {
    const {
      id,
      children,
      workflowFlag,
      pillFlag,
      additionalInfoFlag,
      breadCrumbFlag,
      bannerFlag,
      isNewFCGHeader,
      contentStage,
      activeStep,
      productName,
      stepper,
      pageTitle,
      layoutClass,
      notifyMode,
      notifyTitle,
      notifyMessage,
      notifyAutoClose,
      notifyTimestamp,
      contributeKnwldgFlag,
      isVisibleFPLoader,
      fpLoaderText,
      isBodyScrollable,
      homeIconFlag,
      match,
      history,
      location,
      showSanitizationBanner,
      sanitizationTitle,
      isHeaderShow,
      isFCGLogoClicakble,
      isFooterShow,
      userDetails,
      customClass
    } = this.props;

    const flags = {
      pillFlag: pillFlag,
      additionalInfoFlag: additionalInfoFlag,
      workflowFlag: workflowFlag,
      breadCrumbFlag: breadCrumbFlag,
      bannerFlag: bannerFlag,
      contributeKnwldgFlag: contributeKnwldgFlag,
      homeIconFlag: homeIconFlag,
      showSanitizationBanner: showSanitizationBanner
    };
    const data = {
      contentStage: contentStage,
      activeStep: activeStep,
      stepper: stepper,
      productName: productName,
      pageTitle: pageTitle,
      sanitizationTitle: sanitizationTitle
    };
    const { ENVIRONMENT_MAIN } = CONFIG

    return (
      <ErrorBoundary>
        <div className={`layout layout__page ${!isBodyScrollable ? 'layout--unscrollable' : ''}`} id={id}>
          <Entitlement401RedirectWrapper
            match={match}
            history={history}
            location={location}
          >
            {isHeaderShow ? <BCGHeader environment={CONFIG.ENVIRONMENT === 'local' ? 'qa' : CONFIG.ENVIRONMENT} apiConfig={CONFIG.SHARED_CONFIG} /> : (isNewFCGHeader ? <FcgHeader userDetails={userDetails} isLogoClicakble={isFCGLogoClicakble} /> : <FaceCardGeneratorHeader userDetails={userDetails} />)}
            <div id="main" className={`main ${customClass}`}>
              <Fragment>
                {isHeaderShow && <PageHeader
                  flags={flags}
                  data={data}
                />}
                <ErrorBoundary>
                  <Container
                    className={`layout__container layout__fix ${layoutClass}`}
                    fluid
                  >
                    {children}
                  </Container>
                </ErrorBoundary>
              </Fragment>
            </div>
            {isFooterShow && <section className="footer" >
              <BCGFooter environment={ENVIRONMENT_MAIN === 'local' ? 'dev' : ENVIRONMENT_MAIN} apiConfig={CONFIG.SHARED_CONFIG} />
            </section>}
          </Entitlement401RedirectWrapper>
        </div>
        <Notification
          mode={notifyMode}
          title={notifyTitle}
          message={notifyMessage}
          autoClose={notifyAutoClose}
          timestamp={notifyTimestamp}
        />
        {/* Mandatory to be included in all layouts */}

        {/** Required to keep alive user session so that refresh token can work properly */}

        <UsabillaSetup />
        <FullPageLoader loadingText={fpLoaderText} isVisible={isVisibleFPLoader} />
        <SmartLogicSetup />

        {/* Mandatory to be included in all layouts */}
      </ErrorBoundary>
    );
  };
}

LayoutContainer.propTypes = {
  id: PropTypes.node.isRequired,
  children: PropTypes.node,
  pageTitle: PropTypes.string,
  layoutClass: PropTypes.string,
  bannerComponent: PropTypes.node,
  koButton: PropTypes.bool,
  homeIconFlag: PropTypes.bool,
  isNewFCGHeader: PropTypes.bool,
  isFCGLogoClicakble: PropTypes.bool,
  fpLoaderText: PropTypes.string,
  customClass: PropTypes.string
};

LayoutContainer.defaultProps = {
  pageTitle: "",
  layoutClass: "",
  koButton: false,
  additionalInfoFlag: false,
  pillFlag: false,
  contributeKnwldgFlag: false,
  homeIconFlag: true,
  fpLoaderText: "",
  sanitizationTitle: "",
  showSanitizationBanner: false,
  isHeaderShow: true,
  isFooterShow: true,
  isNewFCGHeader: false,
  isFCGLogoClicakble: false,
  customClass: ""
};

const mapStateToProps = (state) => ({
  notifyMode: notifySelectors.getMode(state),
  notifyTitle: notifySelectors.getTitle(state),
  notifyMessage: notifySelectors.getMessage(state),
  notifyAutoClose: notifySelectors.getAutoClose(state),
  notifyTimestamp: notifySelectors.getTimestamp(state),
  isVisibleFPLoader: globalSelectors.isVisibleFPLoader(state),
  fpLoaderText: globalSelectors.getLoadingText(state),
  isBodyScrollable: globalSelectors.isBodyScrollable(state)
});

const mapDispatchToProps = (dispatch) => ({
  peopleResultsActions: bindActionCreators({ ...peopleResultsActions }, dispatch),
  setLoggedInUserData: (data) => dispatch(setLoggedInUserData(data))
});

export const Layout = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LayoutContainer)
);